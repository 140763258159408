<template>
  <div class="app-container">
    <div class="header">
      <el-button :loading="commitFlag" type="primary" class="btn-wrap save-btn" @click="handleSave"
        >{{ $t('button.preservation') }}</el-button
      >
      <el-button class="btn-wrap preview-btn" @click="preView">预览</el-button>
      <el-button class="btn-wrap return-btn" @click="$router.go(-1)">返回</el-button>
    </div>
    <el-form
      ref="dataForm"
      :model="temp"
      :inline="true"
      label-position="right"
      class="form-container bgFFF"
      label-width="100px"
    >
      <el-row>
        <el-col :span="24">
          <el-form-item label="标题:">
            <el-input
              v-model="temp.title"
              placeholder="请输入文章标题"
              maxlength="100"
              show-word-limit
              class="title-style"
            />
          </el-form-item>
          <el-form-item label="发布渠道：" style="width: 100%">
            <el-checkbox-group v-model="temp.releaseType">
              <el-checkbox label="1">APP</el-checkbox>
              <el-checkbox label="2">微信小程序</el-checkbox>
              <el-checkbox label="4">支付宝小程序</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item
            label="内容:"
            style="width: 100%; margin-top: -20px"
            class="vab-quill-content"
          >
            <!-- <tinymce-editor ref="editor" v-model="temp.content" /> -->
            <quill-editor
              class="ql-editor"
              ref="myQuillEditor"
              v-model="temp.content"
              :min-height="400"
              :options="editorOption"
            ></quill-editor>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <!-- ========================= -->
    <preview-list
      :previewVisible="previewVisible"
      :temp="temp"
      @closeValue="closeValue"
    ></preview-list>
  </div>
</template>

<script>
import _ from "lodash";
import previewList from "./previewList.vue";
require("../../../static/reset.css");

import { quillEditor, Quill } from "vue-quill-editor";
// import * as Quill from "quill"; // 引入编辑器
// quill图片可拖拽上传
import { ImageDrop } from "quill-image-drop-module";
Quill.register("modules/imageDrop", ImageDrop);

import imageResize from "quill-image-resize-module"; // 引用，调整图片大小
Quill.register("modules/imageResize", imageResize);

// quill编辑器的字体
var fonts = [
  "SimSun",
  "SimHei",
  "Microsoft-YaHei",
  "KaiTi",
  "FangSong",
  "Arial",
  "Times-New-Roman",
  "sans-serif",
];
var Font = Quill.import("formats/font");
Font.whitelist = fonts; // 将字体加入到白名单
Quill.register(Font, true);

let fontSize = ["20px", "18px", "16px", "12px"];
Quill.imports["attributors/style/size"].whitelist = fontSize;
Quill.register(Quill.imports["attributors/style/size"]);
export default {
  components: {
    // TinymceEditor,
    // vabQuill,
    previewList,
    quillEditor,
    // Editor,
    // Toolbar
  },
  data() {
    return {
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // 加粗，斜体，下划线，删除线
            // ["blockquote", "code-block"], // 引用，代码块

            // [{ header: 1 }, { header: 2 }], // 标题，键值对的形式；1、2表示字体大小
            [{ list: "ordered" }, { list: "bullet" }], // 列表
            // [{ script: "sub" }, { script: "super" }], // 上下标
            // [{ indent: "-1" }, { indent: "+1" }], // 缩进
            // [{ direction: "rtl" }], // 文本方向

            [{ size: fontSize }], // 文字大小
            // [{ size: ["small", false, "large", "huge"] }], // 字体大小
            // [{ header: [1, 2, 3, 4, 5, 6, false] }], // 几级标题

            [{ color: ["#001A42", "#4F6689", "#7A828A", "#1D70FF"] }], // 字体颜色，字体背景颜色, { background: [] }
            // [{ font: ['Microsoft-YaHei'] }], // 字体
            [{ align: [] }], // 对齐方式

            // ["clean"], // 清除字体样式
            ["link", "image"], // 上传图片、上传视频
          ],
          imageDrop: true,
          // imageResize: {}
          imageResize: {
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              color: "white",
            },
            modules: ["Resize", "DisplaySize", "Toolbar"],
          },
        },
        placeholder: "请输入内容...",
        theme: "snow",
      },
      editor: null,
      html: "<p></p>",
      toolbarConfig: {},
      mode: "default",
      temp: {
        // isReprint: 0,
        // isStick: 0,
        // coverType: 0,
        title: "",
        // cityNames: [],
        releaseType: [],
        // shareTitle: "",
        // shareDescribe: "",
        // introduction: "",
        // categoryId: "",
        // readMultiple: "",
        // pointMultiple: "",
        // jumpUrl: "",
        // upperTime: "",
        // lowerTime: "",
        content: "",
        // userRead: 0,
        // userPoint: 0
      },
      cities: [],
      cityNames: [1],
      imageUrl: "",
      previewVisible: false,
      fileList: [],
      shareFileList: [],
      image: [],
      image2: [],
      coverLimit: 1,
      types: [],
      commitFlag: false,
      informationId: this.$route.query.id,
      hideUploadEdit: false,
      hideUploadEdit2: false,
    };
  },
  created() {
    // this.queryCityList();
    // this.getGradeTree();
    if (this.informationId) {
      let releaseType = this.$route.query.releaseType.split(",");
      this.temp = {
        title: this.$route.query.title,
        content: this.$route.query.content,
        releaseType: releaseType,
      };
    }
  },

  mounted() {},

  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
    },
    // 获取分类列表
    getGradeTree() {
      const opt = {
        url: "/acb/2.0/informationCategory/tree",
        method: "get",
        data: {},
        success: (res) => {
          this.types = res.value;
        },
      };
      this.$request(opt);
    },
    // 获取城市列表
    queryCityList() {
      const opt = {
        url: "/acb/2.0/area/provinceWithCity",
        method: "get",
        data: {},
        success: (res) => {
          res.value[0].depth = 1;
          delete res.value[0].childrenAreas;
          this.cities = res.value;
        },
      };
      this.$request(opt);
    },
    // 处理表单数据
    handleFormData(temp) {
      const tempData = {};
      // tempData.isReprint = temp.isReprint;
      // tempData.coverType = temp.coverType;
      tempData.title = temp.title;
      // tempData.introduction = temp.introduction;
      // tempData.author = temp.author;
      // tempData.isStick = temp.isStick;
      // const newCityNames = [];
      // const tempCityNames = temp.cityNames;
      // tempCityNames.forEach((item) => {
      //   const _item = item.pop();
      //   newCityNames.push({ cityId: _item });
      // });
      // tempData.informationCityList = newCityNames;
      // const categoryId = temp.categoryId;
      // if (typeof categoryId === "string") {
      //   tempData.categoryId = categoryId;
      // } else {
      //   if (categoryId.length === 1) {
      //     tempData.categoryId = categoryId[0];
      //   } else {
      //     tempData.categoryId = categoryId[1];
      //   }
      // }
      // if (!this.informationId) {
      //   tempData.userPoint = 0;
      //   tempData.userRead = 0;
      // }
      tempData.releaseType = temp.releaseType;
      tempData.content = temp.content;
      // tempData.readMultiple = temp.readMultiple;
      // tempData.shareTitle = temp.shareTitle;
      // tempData.pointMultiple = temp.pointMultiple;
      // tempData.shareDescribe = temp.shareDescribe;
      // if (temp.upperTime) {
      //   tempData.upperTime = this.$moment(temp.upperTime).format(
      //     "YYYY-MM-DD HH:mm:ss"
      //   );
      // }
      // if (temp.lowerTime) {
      //   tempData.lowerTime = this.$moment(temp.lowerTime).format(
      //     "YYYY-MM-DD HH:mm:ss"
      //   );
      // }
      // if (temp.isReprint === 0) {
      //   tempData.content = temp.content;
      // } else {
      //   tempData.jumpUrl = temp.jumpUrl;
      // }
      return tempData;
    },
    handleSave() {
      console.log("保存：：：", this.handleFormData(this.temp));
      if (this.informationId) {
        this.updateData();
      } else {
        this.createData();
      }
    },
    // 新增保存
    createData() {
      if (!this.checkEmpty()) {
        return;
      }
      // this.commitFlag = true;
      const tempData = this.handleFormData(this.temp);
      tempData.releaseType = tempData.releaseType.join(",");
      tempData.id = this.informationId;

      const opt = {
        url: "/acb/2.0/question/add",
        method: "post",
        config: {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
          },
        },
        data: tempData,
        success: (res) => {
          this.commitFlag = false;
          if (res.state == 0) {
            this.$message({
              message: res.desc,
              type: "success",
            });
            sessionStorage.setItem("saveShelvesType", 0);
            this.$router.push({ path: "/question" });
          }
        },
      };
      this.$request(opt);
    },
    evil(fn) {
      let Fn = Function;
      return new Fn("return " + fn)();
    },
    // 编辑保存
    updateData() {
      if (!this.checkEmpty()) {
        return;
      }
      this.commitFlag = true;
      const tempData = this.handleFormData(this.temp);
      tempData.informationId = this.informationId;
      tempData.status = 0; // 编辑保存后总是已保存状态
      tempData.updateUser = sessionStorage.userId;
      tempData.releaseType = tempData.releaseType.join(",");
      tempData.id = this.informationId;
      const opt = {
        url: "/acb/2.0/question/update",
        method: "post",
        config: {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
          },
        },
        data: tempData,
        success: (res) => {
          this.$message({
            message: res.desc,
            type: "success",
          });
          this.$emit("shelvesHandler", 0);
          this.$router.push({ path: "/question" });
        },
      };
      this.$request(opt);
    },
    // 表单非空验证
    checkEmpty() {
      if (!this.temp.title) {
        this.$message.warning("标题不能为空");
        return false;
      }
      if (this.temp.releaseType.length <= 0) {
        this.$message.warning("发布渠道不能为空");
        return false;
      }
      return true;
    },

    // 修改封面图片模式（1/3图模式）
    coverChange(val) {
      if (val === 1) {
        this.coverLimit = 3;
      } else {
        this.coverLimit = 1;
      }
      this.hideUploadEdit = this.fileList.length >= this.coverLimit;
    },
    // 图片墙删除图片（分享缩略图）
    handleRemoveForShare(file, fileList) {
      this.shareFileList = fileList;
      this.image2 = [];
      if (this.shareFileList.length > 0) {
        this.shareFileList.forEach((item) => {
          this.image2.push(item.raw);
        });
      }
      this.hideUploadEdit2 = this.shareFileList.length >= 1;
    },
    // 图片墙删除图片
    handleRemove(file, fileList) {
      // this.fileList = fileList
      this.fileList = [];
      this.image = [];
      if (fileList.length > 0) {
        fileList.forEach((item) => {
          this.image.push(item.raw);
          this.fileList.push(item.url);
        });
      }

      this.hideUploadEdit = this.fileList.length >= this.coverLimit;
    },
    // 图片超出处理（分享缩略图）
    handleExceedForShare(files, fileList) {
      this.$message.warning("只能上传一张分享缩略图");
    },
    // 图片超出处理
    handleExceed(files, fileList) {
      // this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`)
      const type = this.temp.coverType;
      if (type === 0) {
        this.$message.warning("单图模式只能上传一张图片");
      } else if (type === 1) {
        this.$message.warning("三图模式只能上传三张图片");
      }
    },
    // 上传到阿里云OSS（编辑器上方的上传按钮）
    fnUploadRequest(file) {
      const image = new FormData();
      image.append("image", file.file, "img");
      const opt = {
        url: "/acb/2.0/information/uploadInfo",
        method: "post",
        data: image,
        config: {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
        success: (res) => {
          this.imageUrl = res.value;
        },
      };
      this.$request(opt);
    },
    // 上传到阿里云OSS
    fnUploadRequest2(file) {
      const fileItem = URL.createObjectURL(file.file);
      this.fileList.push(fileItem);
      this.image.push(file.file);
      this.hideUploadEdit = this.fileList.length >= this.coverLimit;
    },
    // 上传到阿里云OSS（分享缩略图）
    fnUploadRequest3(file) {
      const fileItem = URL.createObjectURL(file.file);
      this.shareFileList.push(fileItem);
      this.image2.push(file.file);
      this.hideUploadEdit2 = this.shareFileList.length >= 1;
    },
    // 上传前的验证
    beforeAvatarUpload(file) {
      const suffix = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isJpg = suffix === "jpg";
      const isjpeg = suffix === "jpeg";
      const isPng = suffix === "png";
      const isLt2M = file.size / 1024 < 100;
      if (!isJpg && !isjpeg && !isPng) {
        this.$message.warning("图片格式错误，只支持jpg、jpeg和png格式!");
        return false;
      }
      if (!isLt2M) {
        this.$message.warning("上传图片大小不能超过 100kb!");
        return false;
      }
    },
    // 上传前的验证
    beforeAvatarUpload2(file) {
      const suffix = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isJpg = suffix === "jpg";
      const isjpeg = suffix === "jpeg";
      const isPng = suffix === "png";
      const isGif = suffix === "gif";
      const isBmp = suffix === "bmp";
      const isLt2M = file.size / 1024 / 1024 < 1;
      if (!isJpg && !isjpeg && !isPng && !isGif && !isBmp) {
        this.$message.error("图片格式错误，只支持jpg、jpeg、png、bpm和gif格式!");
        return false;
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 1MB!");
        return false;
      }
    },
    // 预览
    preView() {
      this.previewVisible = true;
    },

    closeValue() {
      this.previewVisible = false;
    },
  },
  beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁编辑器
  },
};
</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>
.app-container {
  clear: both;
  background: #fff;
  margin-top: 20px;

  >>>.el-icon-close:before {
    font-size: 16px;
    font-weight: bold;
    color: rgba(161, 176, 200, 1);
  }

  .vab-quill-content {
    ::v-deep {
      .el-form-item__content {
        width: 90%;
        line-height: normal;
      }
    }
  }

  .header {
    width: 100%;
    display: inline-block;
    margin-top: 20px;
  }

  .btn-wrap {
    padding: 7px 20px;
    color: #527BFF;
    border: 1px solid rgba(76, 111, 255, 0.24);
  }

  .save-btn {
    float: right;
    margin-right: 32px;
    color: #fff;
    background: #527bff;
  }

  .preview-btn, .return-btn {
    float: right;
    margin-right: 16px;
    margin-left: 0;
  }

  .form-container {
    padding: 0 40px;
  }

  .cur {
    color: #FFF;
    background-color: #909399;
    border-color: #909399;
  }

  .label-slot {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;

    img {
      width: 20px;
      height: 20px;
    }
  }

  .title-style {
    width: 383px;

    >>>.el-input__inner {
      padding: 0 70px 0 15px;
    }
  }
}

>>>.hide {
  .el-upload--picture-card {
    display: none;
  }
}

>>>.ql-tooltip {
  left: 0 !important;
}
</style>
<style>
.ql-container div > span,
div > span > svg {
  display: none;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="12px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="12px"]::before {
  content: "注释 12px";
  vertical-align: top;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="16px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {
  content: "正文 16px";
  vertical-align: top;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="18px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
  content: "副标题 18px";
  vertical-align: top;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="20px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="20px"]::before {
  content: "一级标题 20px";
  vertical-align: top;
}

.ql-editor {
  min-width: 720px;
  min-height: 500px;
  padding: 0;
}

.ql-container {
  padding: 13px 24px;
}

.ql-container .ql-editor p,
.ql-container .ql-editor span {
  padding-bottom: 12px;
  font-size: 16px;
}

.ql-editor .ql-size-12 {
  font-size: 12px;
}
.ql-editor .ql-size-16 {
  font-size: 16px;
}
.ql-editor .ql-size-18 {
  font-size: 18px;
}
.ql-editor .ql-size-20 {
  font-size: 20px;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="12px"]::before {
  font-size: 12px;
  color: #7a828a;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {
  font-size: 16px;
  color: #4f6689;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
  font-size: 18px;
  color: #001a42;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="20px"]::before {
  font-size: 20px;
  color: #001a42;
}
</style>
<style>
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="SimSun"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="SimSun"]::before {
  content: "宋体";
  font-family: "SimSun";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="SimHei"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="SimHei"]::before {
  content: "黑体";
  font-family: "SimHei";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="Microsoft-YaHei"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="Microsoft-YaHei"]::before {
  content: "PingFang";
  font-family: "Microsoft YaHei";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="PingFangSC-Regular, PingFang SC"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="PingFangSC-Regular, PingFang SC"]::before {
  content: "PingFang";
  font-family: "PingFangSC-Regular, PingFang SC";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="KaiTi"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="KaiTi"]::before {
  content: "楷体";
  font-family: "KaiTi";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="FangSong"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="FangSong"]::before {
  content: "仿宋";
  font-family: "FangSong";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="Arial"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="Arial"]::before {
  content: "Arial";
  font-family: "Arial";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="Times-New-Roman"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="Times-New-Roman"]::before {
  content: "Times New Roman";
  font-family: "Times New Roman";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="sans-serif"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="sans-serif"]::before {
  content: "sans-serif";
  font-family: "sans-serif";
}

.ql-font-SimSun {
  font-family: "SimSun";
}
.ql-font-SimHei {
  font-family: "SimHei";
}
.ql-font-Microsoft-YaHei {
  font-family: "Microsoft YaHei";
}
.ql-font-KaiTi {
  font-family: "KaiTi";
}
.ql-font-FangSong {
  font-family: "FangSong";
}
.ql-font-Arial {
  font-family: "Arial";
}
.ql-font-Times-New-Roman {
  font-family: "Times New Roman";
}
.ql-font-sans-serif {
  font-family: "sans-serif";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="12px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="12px"]::before {
  content: "注释 12px";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="16px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {
  content: "正文 16px";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="18px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
  content: "副标题 18px";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="20px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="20px"]::before {
  content: "一级标题 20px";
}

.form-container .ql-snow .ql-picker.ql-size {
  width: 135px;
}

.form-container .ql-editor.ql-blank::before {
  padding: 0 10px;
}
</style>
